export const REGIME_WHO = 'Current WHO guidelines'
export const REGIME_COVERAGE = 'Enhanced coverage'
export const REGIME_FREQUENCY = 'Enhanced frequency'
export const REGIME_NO_MDA = 'No MDA'

export const REGIME_LABELS = {
  'Current WHO guidelines': 'WHO strategy unlimited duration',
  'Enhanced coverage': 'Enhanced coverage unlimited duration',
  'Enhanced frequency': 'Enhanced frequency unlimited duration',
  'No MDA': 'MDA halted in 2018',
}

export const NO_DATA = '[no data]'
