import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import { BrowserRouter as Router } from 'react-router-dom'
import RootStore from './store/rootStore'
import App from './App'
import './index.css'

import * as serviceWorker from './serviceWorker'

const { dataAPI, uiState } = new RootStore()

ReactDOM.render(
  <Provider dataAPI={dataAPI} uiState={uiState}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
